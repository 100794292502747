import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Container from "../components/Container"

const ErrorPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            titleHead
          }
        }
      }
    `
  )
  return (
    <Layout title={site.siteMetadata.titleHead} subtitle="Page not found!">
      <Helmet title={"404"} />
          <Container css={css` flex-direction: column !important; text-align: center;}`}>
        <h1>Woops, something went wrong.</h1>
        <h3>This page does not exist or is no longer reachable.<br/>
          You can return to the <Link to="/">Homepage</Link>.
        </h3>
      </Container>
    </Layout>
  )
}
export default ErrorPage

ErrorPage.propTypes = {
  center: PropTypes.object,
}
